import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  return (
    <header>

      {/* Top text & two buttons */}
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Arav Misra</h1>
        <h5 className="text-light">Electrical Engineering and Computer Sciences @ Berkeley</h5>
        <CTA/>
        <HeaderSocial/>

        <div className="me">
          <img src={ME} alt="me" />
        </div>
      </div>
      
    </header>
  )
}

export default Header