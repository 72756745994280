import React from 'react'
import './projects.css'

import IMG1 from '../../assets/scheme.jpg'
import IMG2 from '../../assets/mri.png'

/* Process to add new project: import new image as above. Add new article to data array below */

const data = [
{
  id: 1,
  image: IMG1,
  title: 'Scheme Interpreter',
  github: 'https://gitfront.io/r/user-9279571/gter7jZYdpsR/scheme-interpreter/',
  demo: 'https://tranquil-manuscript-af6.notion.site/Scheme-Interpreter-261da131d34a40c292e4dbdb3cfe5450'
},
{
  id: 2,
  image: IMG2,
  title: 'MRI Research',
  github: 'https://github.com/aravmisra/makingnmr',
  demo: 'http://science.slc.edu/mfrey/post/summer-2021-summary/'
}
]

const Portfolio = () => {
  return (
    <section id='projects'>
      <h5>What I've Been Up To</h5>
      <h2>Projects</h2>

      <div className="container project__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return(
              <article key = {id} className='project__item'>
              <div className="project__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="project__item-cta">
                <a href={github} className='btn'>Github</a>
                <a href={demo} className='btn btn-primary' target='_blank'>Information</a>
              </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio