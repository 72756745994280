import React from 'react'
import './about.css'
import ME from '../../assets/aboutme.JPG'
import {BsCodeSlash} from 'react-icons/bs'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className='about__content'>
          <div className="about__cards">
            <article className='about__card'>
              <BsCodeSlash className='about__icon'/>
              <h5>Skills</h5>
              <small>Python, Java, HTML/CSS, React.js, Node.js, MATLAB, Solidworks, Arduino</small>
            </article>
          </div>

          <p>
            Hi there! My name's Arav. I'm currently studying Electrical Engineering and Computer Sciences at UC Berkeley. 
            I have a passion for software development and I love to read, watch baseball, and enjoy nature in my free time.
            Feel free to contact me about anything.
          </p>
          <a href="#contact" className='btn btn-primary'>Send Me a Message</a>
        </div>
      </div>
    </section>
  )
}

export default About